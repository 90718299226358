<template>
    <div class="preloader">
        <div class="preloader-wrapper">
            <svg width="226" height="74" viewBox="0 0 226 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M51.1074 4.92834H57.4309V16.307H71.2794V4.92834H77.6029V33.6661H71.2794V21.9404H57.4309V33.6661H51.1074V4.92834Z"
                    fill="white" />
                <path
                    d="M95.2305 28.3759C100.289 28.3759 103.852 24.4102 103.852 19.2954C103.852 14.1805 100.289 10.2148 95.2305 10.2148C90.1156 10.2148 86.5528 14.1805 86.5528 19.2954C86.5528 24.4102 90.1156 28.3759 95.2305 28.3759ZM95.2305 34.2406C86.7244 34.2406 80.1733 27.6298 80.1733 19.2954C80.1733 10.9609 86.7244 4.3501 95.2305 4.3501C103.737 4.3501 110.288 10.9609 110.288 19.2954C110.288 27.6298 103.737 34.2406 95.2305 34.2406Z"
                    fill="white" />
                <path
                    d="M127.632 10.0989C122.517 10.0989 119.01 14.1207 119.01 19.2952C119.01 24.4697 122.517 28.4914 127.632 28.4914C132.691 28.4914 136.198 24.4697 136.198 19.2952C136.198 14.1244 132.691 10.0989 127.632 10.0989ZM113.205 4.92817H119.529V8.77827C121.137 6.48015 124.644 4.35364 128.781 4.35364C136.657 4.35364 142.633 11.0801 142.633 19.2989C142.633 27.5177 136.657 34.2442 128.781 34.2442C124.644 34.2442 121.137 32.1177 119.529 29.8195V45.6826H113.205V4.92817Z"
                    fill="white" />
                <path
                    d="M159.612 10.0989C154.497 10.0989 150.99 14.1207 150.99 19.2952C150.99 24.4697 154.497 28.4914 159.612 28.4914C164.67 28.4914 168.177 24.4697 168.177 19.2952C168.174 14.1244 164.667 10.0989 159.612 10.0989ZM145.185 4.92817H151.508V8.77827C153.116 6.48015 156.623 4.35364 160.761 4.35364C168.636 4.35364 174.613 11.0801 174.613 19.2989C174.613 27.5177 168.636 34.2442 160.761 34.2442C156.623 34.2442 153.116 32.1177 151.508 29.8195V45.6826H145.185V4.92817Z"
                    fill="white" />
                <path
                    d="M183.231 16.3667H199.094C198.116 11.7108 194.841 9.64026 191.334 9.64026C187.368 9.64026 184.092 11.9384 183.231 16.3667ZM191.449 34.2406C182.884 34.2406 176.735 27.9171 176.735 19.2954C176.735 10.7893 182.656 4.3501 191.505 4.3501C200.299 4.3501 206.451 11.0766 205.872 20.8473H182.996C183.227 25.216 186.558 28.6632 191.673 28.6632C194.833 28.6632 197.594 27.3985 198.915 24.6415H205.525C203.921 30.0436 198.75 34.2406 191.449 34.2406Z"
                    fill="white" />
                <path
                    d="M209.286 4.92826H215.61V10.0431C216.703 6.7675 219.919 4.64099 223.198 4.64099C224.004 4.64099 224.806 4.70068 225.668 4.8723V11.3675C224.575 11.0243 223.657 10.8489 222.508 10.8489C218.885 10.8489 215.61 13.8932 215.61 19.6422V33.666H209.286V4.92826Z"
                    fill="white" />
                <path d="M114.44 51.4579H113.205V60.2437H114.44V51.4579Z" fill="white" />
                <path d="M118.342 52.5211H115.485V51.4579H122.439V52.5211H119.577V60.24H118.342V52.5211Z"
                    fill="white" />
                <path d="M125.964 55.2484H122.11V56.2893H125.964V55.2484Z" fill="white" />
                <path
                    d="M132.941 60.2398V52.521H129.841L129.781 54.0468C129.74 54.9982 129.669 55.8562 129.568 56.621C129.467 57.3858 129.318 58.035 129.12 58.5722C128.923 59.1094 128.669 59.5198 128.363 59.8071C128.057 60.0943 127.677 60.2361 127.225 60.2361H126.863V59.1691H127.046C127.337 59.1691 127.576 59.0236 127.766 58.7326C127.956 58.4416 128.109 58.0462 128.225 57.5462C128.341 57.0463 128.43 56.4606 128.486 55.7965C128.542 55.1287 128.583 54.4199 128.609 53.67L128.669 51.454H134.183V60.2361H132.941V60.2398Z"
                    fill="white" />
                <path
                    d="M137.716 57.2853H140.988L139.339 52.704L137.716 57.2853ZM138.66 51.4579H140.089L143.398 60.2437H142.066L141.376 58.3411H137.339L136.66 60.2437H135.399L138.66 51.4579Z"
                    fill="white" />
                <path
                    d="M147.871 59.1728C148.475 59.1728 148.945 59.0311 149.281 58.7475C149.617 58.464 149.785 58.0574 149.785 57.5239C149.785 56.9344 149.613 56.524 149.278 56.289C148.942 56.054 148.453 55.9383 147.823 55.9383H145.86V59.1728H147.871ZM145.856 54.9198H147.916C148.893 54.9198 149.662 55.1287 150.218 55.5429C150.774 55.9607 151.053 56.6173 151.053 57.5127C151.053 57.9977 150.975 58.4118 150.818 58.755C150.662 59.0982 150.441 59.378 150.165 59.5981C149.886 59.8145 149.55 59.9787 149.154 60.0831C148.759 60.1876 148.326 60.2398 147.86 60.2398H144.625V51.454H150.527V52.521H145.864V54.9198H145.856Z"
                    fill="white" />
                <path
                    d="M153.419 55.8414C153.419 56.4084 153.493 56.9084 153.643 57.3449C153.792 57.7814 153.993 58.147 154.247 58.4492C154.501 58.7476 154.803 58.9752 155.15 59.1281C155.497 59.2811 155.874 59.3595 156.276 59.3595C156.672 59.3595 157.045 59.2811 157.396 59.1281C157.746 58.9752 158.049 58.7476 158.306 58.4492C158.56 58.1507 158.761 57.7814 158.91 57.3449C159.06 56.9084 159.134 56.4084 159.134 55.8414C159.134 55.278 159.06 54.7744 158.91 54.3379C158.761 53.9014 158.56 53.5358 158.306 53.2411C158.052 52.9463 157.75 52.7225 157.396 52.5695C157.045 52.4166 156.672 52.3382 156.276 52.3382C155.874 52.3382 155.497 52.4166 155.15 52.5695C154.803 52.7225 154.501 52.9463 154.247 53.2411C153.993 53.5358 153.792 53.9014 153.643 54.3379C153.493 54.7744 153.419 55.278 153.419 55.8414ZM160.399 55.8414C160.399 56.5054 160.302 57.1136 160.115 57.6694C159.925 58.2253 159.649 58.7066 159.291 59.1095C158.933 59.5124 158.496 59.8295 157.989 60.0534C157.481 60.2809 156.911 60.3929 156.28 60.3929C155.65 60.3929 155.083 60.2809 154.572 60.0534C154.064 59.8258 153.628 59.5124 153.27 59.1095C152.911 58.7066 152.635 58.2253 152.445 57.6694C152.255 57.1136 152.161 56.5017 152.161 55.8414C152.161 55.181 152.255 54.5692 152.445 54.0133C152.635 53.4575 152.908 52.9762 153.27 52.577C153.628 52.1778 154.064 51.8644 154.572 51.6369C155.079 51.4093 155.65 51.2974 156.28 51.2974C156.911 51.2974 157.481 51.4093 157.989 51.6369C158.496 51.8644 158.933 52.1778 159.291 52.577C159.649 52.9762 159.925 53.4537 160.115 54.0133C160.302 54.5692 160.399 55.181 160.399 55.8414Z"
                    fill="white" />
                <path
                    d="M163.413 52.4988V55.6848H165.025C165.349 55.6848 165.648 55.6699 165.928 55.6438C166.207 55.6139 166.45 55.5468 166.655 55.4386C166.86 55.3304 167.021 55.1737 167.14 54.9648C167.256 54.7596 167.316 54.4723 167.316 54.1104C167.316 53.756 167.256 53.4687 167.14 53.2561C167.025 53.0434 166.86 52.8793 166.655 52.7636C166.45 52.6517 166.207 52.5771 165.928 52.5473C165.648 52.5137 165.349 52.4988 165.025 52.4988H163.413ZM164.928 51.4579C165.461 51.4579 165.95 51.4952 166.398 51.5661C166.845 51.6407 167.233 51.775 167.554 51.9727C167.879 52.1705 168.129 52.4428 168.304 52.786C168.479 53.1293 168.569 53.5732 168.569 54.1142C168.569 54.6626 168.479 55.1103 168.304 55.4535C168.125 55.7967 167.875 56.0653 167.554 56.2593C167.23 56.4533 166.845 56.5876 166.398 56.6585C165.95 56.7331 165.458 56.7667 164.928 56.7667H163.413V60.2437H162.178V51.4579H164.928Z"
                    fill="white" />
                <path
                    d="M170.285 57.2853H173.557L171.908 52.704L170.285 57.2853ZM171.229 51.4579H172.658L175.967 60.2437H174.635L173.945 58.3411H169.909L169.23 60.2437H167.969L171.229 51.4579Z"
                    fill="white" />
                <path d="M178.015 52.5211H175.154V51.4579H182.108V52.5211H179.25V60.24H178.015V52.5211Z" fill="white" />
                <path
                    d="M183.525 55.8414C183.525 56.4084 183.6 56.9084 183.749 57.3449C183.898 57.7814 184.1 58.147 184.354 58.4492C184.607 58.7476 184.909 58.9752 185.256 59.1281C185.603 59.2811 185.98 59.3595 186.383 59.3595C186.779 59.3595 187.152 59.2811 187.502 59.1281C187.853 58.9752 188.155 58.7476 188.413 58.4492C188.666 58.1507 188.868 57.7814 189.017 57.3449C189.166 56.9084 189.241 56.4084 189.241 55.8414C189.241 55.278 189.166 54.7744 189.017 54.3379C188.868 53.9014 188.666 53.5358 188.413 53.2411C188.159 52.9463 187.857 52.7225 187.502 52.5695C187.152 52.4166 186.779 52.3382 186.383 52.3382C185.98 52.3382 185.603 52.4166 185.256 52.5695C184.909 52.7225 184.607 52.9463 184.354 53.2411C184.1 53.5358 183.898 53.9014 183.749 54.3379C183.6 54.7744 183.525 55.278 183.525 55.8414ZM190.505 55.8414C190.505 56.5054 190.408 57.1136 190.222 57.6694C190.032 58.2253 189.756 58.7066 189.397 59.1095C189.039 59.5124 188.603 59.8295 188.095 60.0534C187.588 60.2809 187.017 60.3929 186.387 60.3929C185.756 60.3929 185.189 60.2809 184.678 60.0534C184.171 59.8258 183.734 59.5124 183.376 59.1095C183.018 58.7066 182.742 58.2253 182.552 57.6694C182.361 57.1136 182.268 56.5017 182.268 55.8414C182.268 55.181 182.361 54.5692 182.552 54.0133C182.742 53.4575 183.014 52.9762 183.376 52.577C183.734 52.1778 184.171 51.8644 184.678 51.6369C185.185 51.4093 185.756 51.2974 186.387 51.2974C187.017 51.2974 187.588 51.4093 188.095 51.6369C188.603 51.8644 189.039 52.1778 189.397 52.577C189.756 52.9762 190.032 53.4537 190.222 54.0133C190.408 54.5692 190.505 55.181 190.505 55.8414Z"
                    fill="white" />
                <path
                    d="M193.523 52.4986V55.6847H195.135C195.46 55.6847 195.758 55.6697 196.038 55.6436C196.318 55.6138 196.56 55.5466 196.765 55.4384C196.971 55.3303 197.131 55.1736 197.25 54.9646C197.366 54.7595 197.426 54.4722 197.426 54.1103C197.426 53.7559 197.366 53.4686 197.25 53.256C197.135 53.0433 196.971 52.8792 196.765 52.7635C196.56 52.6516 196.318 52.577 196.038 52.5471C195.758 52.5136 195.46 52.4986 195.135 52.4986H193.523ZM195.038 51.4578C195.572 51.4578 196.06 51.4951 196.508 51.566C196.956 51.6406 197.344 51.7749 197.665 51.9726C197.989 52.1703 198.239 52.4427 198.414 52.7859C198.59 53.1291 198.679 53.5731 198.679 54.114C198.679 54.6625 198.59 55.1101 198.414 55.4534C198.235 55.7966 197.985 56.0652 197.665 56.2592C197.34 56.4532 196.956 56.5875 196.508 56.6584C196.06 56.733 195.568 56.7666 195.038 56.7666H193.523V60.2436H192.289V51.4578H195.038Z"
                    fill="white" />
                <path
                    d="M205.988 53.2636L201.265 60.24H200.03V51.4579H201.265V58.4231L205.988 51.4579H207.227V60.24H205.988V53.2636Z"
                    fill="white" />
                <path
                    d="M213.076 52.521C212.722 52.521 212.394 52.5434 212.103 52.5881C211.808 52.6329 211.554 52.7187 211.338 52.8418C211.125 52.9687 210.957 53.1403 210.842 53.3566C210.726 53.573 210.666 53.8528 210.666 54.1923C210.666 54.5169 210.73 54.7855 210.853 55.0056C210.98 55.2257 211.148 55.3973 211.36 55.5279C211.573 55.6585 211.83 55.748 212.125 55.8003C212.42 55.8525 212.737 55.8786 213.076 55.8786H214.371V52.5247H213.076V52.521ZM215.61 60.2398H214.375V56.9456H213.091C213.028 56.9456 212.957 56.9419 212.886 56.9381C212.812 56.9344 212.744 56.9307 212.681 56.9269C212.618 56.9232 212.569 56.9195 212.535 56.9195L210.353 60.2398H208.924L211.301 56.6285C210.745 56.442 210.29 56.1472 209.939 55.7443C209.588 55.3414 209.413 54.8116 209.413 54.1588C209.413 53.6402 209.506 53.2112 209.693 52.8605C209.879 52.5135 210.137 52.2374 210.472 52.0285C210.808 51.8233 211.204 51.6741 211.659 51.5883C212.114 51.4987 212.618 51.454 213.166 51.454H215.613V60.2398H215.61Z"
                    fill="white" />
                <path
                    d="M118.585 63.9294H115.469L115.447 65.8321C115.432 67.0446 115.324 68.0444 115.126 68.8353C114.929 69.6262 114.694 70.2082 114.417 70.5813H118.585V63.9294ZM120.887 73.4763H119.749V71.6483H113.604V73.4763H112.466V70.5813H112.843C113.1 70.5813 113.317 70.4432 113.492 70.1709C113.664 69.8948 113.806 69.5255 113.918 69.0554C114.026 68.5853 114.108 68.0407 114.16 67.4214C114.212 66.7983 114.242 66.138 114.25 65.4329L114.287 62.8661H119.823V70.585H120.89V73.4763H120.887Z"
                    fill="white" />
                <path
                    d="M127.695 71.6485V63.9296H124.595L124.535 65.4555C124.494 66.4068 124.423 67.2649 124.323 68.0297C124.222 68.7945 124.073 69.4436 123.875 69.9808C123.677 70.518 123.424 70.9284 123.118 71.2157C122.812 71.503 122.431 71.6447 121.98 71.6447H121.618V70.5777H121.801C122.092 70.5777 122.331 70.4322 122.521 70.1412C122.711 69.8502 122.864 69.4548 122.98 68.9549C123.099 68.455 123.185 67.8692 123.241 67.2052C123.297 66.5374 123.338 65.8285 123.364 65.0787L123.424 62.8626H128.938V71.6447H127.695V71.6485Z"
                    fill="white" />
                <path
                    d="M134.783 63.9296C134.429 63.9296 134.101 63.952 133.81 63.9967C133.515 64.0415 133.261 64.1273 133.045 64.2504C132.832 64.3773 132.664 64.5489 132.549 64.7653C132.433 64.9817 132.373 65.2615 132.373 65.601C132.373 65.9255 132.437 66.1941 132.56 66.4143C132.687 66.6344 132.855 66.806 133.067 66.9366C133.28 67.0671 133.537 67.1567 133.832 67.2089C134.127 67.2611 134.444 67.2872 134.783 67.2872H136.078V63.9296H134.783ZM137.317 71.6485H136.082V68.3542H134.798C134.735 68.3542 134.664 68.3505 134.593 68.3468C134.519 68.343 134.451 68.3393 134.388 68.3356C134.325 68.3318 134.276 68.3281 134.242 68.3281L132.06 71.6485H130.631L133.008 68.0371C132.452 67.8506 131.997 67.5559 131.646 67.1529C131.295 66.75 131.12 66.2203 131.12 65.5674C131.12 65.0488 131.213 64.6198 131.4 64.2691C131.586 63.9221 131.844 63.6461 132.179 63.4371C132.515 63.2319 132.911 63.0827 133.366 62.9969C133.821 62.9074 134.325 62.8626 134.873 62.8626H137.32V71.6485H137.317Z"
                    fill="white" />
                <path
                    d="M145.759 70.5815C146.364 70.5815 146.834 70.4397 147.17 70.1562C147.505 69.8726 147.673 69.466 147.673 68.9325C147.673 68.343 147.502 67.9327 147.166 67.6976C146.83 67.4626 146.341 67.3469 145.711 67.3469H143.749V70.5815H145.759ZM143.749 66.3284H145.808C146.785 66.3284 147.554 66.5374 148.11 66.9515C148.666 67.3693 148.945 68.0259 148.945 68.9213C148.945 69.4063 148.867 69.8204 148.71 70.1636C148.554 70.5069 148.334 70.7867 148.058 71.0068C147.778 71.2232 147.442 71.3873 147.047 71.4918C146.651 71.5962 146.218 71.6485 145.752 71.6485H142.517V62.8626H148.419V63.9296H143.756V66.3284H143.749Z"
                    fill="white" />
                <path
                    d="M156.556 64.6683L151.829 71.6485H150.594V62.8626H151.829V69.8316L156.556 62.8626H157.791V71.6485H156.556V64.6683Z"
                    fill="white" />
                <path
                    d="M164.491 67.0075C164.969 67.1456 165.346 67.3843 165.625 67.7238C165.905 68.0633 166.043 68.5446 166.043 69.1639C166.043 69.6228 165.95 70.022 165.763 70.3503C165.577 70.6823 165.331 70.9546 165.017 71.1673C164.708 71.3799 164.349 71.5404 163.95 71.6411C163.551 71.7418 163.133 71.7941 162.697 71.7941C161.753 71.7941 161.003 71.5553 160.455 71.074C159.906 70.5928 159.544 69.9436 159.376 69.1303H160.589C160.693 69.6638 160.932 70.0667 161.309 70.3428C161.686 70.6189 162.174 70.7532 162.783 70.7532C163.447 70.7532 163.943 70.6077 164.279 70.313C164.614 70.0182 164.782 69.6228 164.782 69.1303C164.782 68.6379 164.622 68.2611 164.297 68.0036C163.973 67.7462 163.473 67.6156 162.794 67.6156H161.753V66.6083H162.723C163.241 66.6083 163.659 66.489 163.976 66.2502C164.293 66.0114 164.454 65.6645 164.454 65.2019C164.454 64.7243 164.301 64.3625 163.999 64.1162C163.697 63.87 163.282 63.7469 162.756 63.7469C162.514 63.7469 162.283 63.7842 162.059 63.8551C161.835 63.9297 161.637 64.0379 161.466 64.1871C161.29 64.3363 161.148 64.5229 161.029 64.743C160.913 64.9668 160.839 65.2205 160.805 65.5115H159.582C159.638 65.0899 159.749 64.7094 159.921 64.3587C160.093 64.0118 160.313 63.7133 160.589 63.4671C160.865 63.2209 161.19 63.0306 161.563 62.8963C161.939 62.762 162.357 62.6948 162.816 62.6948C163.212 62.6948 163.585 62.7471 163.939 62.8515C164.29 62.956 164.596 63.1089 164.861 63.3104C165.122 63.5118 165.331 63.7618 165.484 64.0565C165.637 64.3513 165.715 64.6833 165.715 65.0564C165.715 65.5078 165.607 65.8958 165.394 66.2204C165.181 66.5561 164.879 66.8135 164.491 67.0075Z"
                    fill="white" />
                <path
                    d="M174.803 62.8626V71.6485H173.568V67.6603H168.949V71.6485H167.715V62.8626H168.949V66.5933H173.568V62.8626H174.803Z"
                    fill="white" />
                <path
                    d="M177.131 62.8626H183.089V63.9296H178.366V66.5933H182.813V67.6603H178.366V70.5815H183.089V71.6485H177.131V62.8626Z"
                    fill="white" />
                <path
                    d="M192.103 68.8504C192.006 69.3354 191.842 69.7607 191.61 70.1301C191.379 70.4994 191.099 70.8053 190.767 71.0553C190.435 71.3053 190.062 71.4918 189.64 71.6186C189.219 71.7417 188.771 71.8052 188.294 71.8052C187.663 71.8052 187.104 71.6932 186.611 71.4657C186.119 71.2381 185.701 70.9247 185.358 70.5218C185.014 70.1189 184.753 69.6376 184.578 69.078C184.399 68.5221 184.313 67.9066 184.313 67.235C184.313 66.556 184.41 65.9367 184.604 65.3809C184.798 64.825 185.074 64.3475 185.428 63.952C185.783 63.5565 186.212 63.2506 186.712 63.0305C187.212 62.8141 187.775 62.7022 188.398 62.7022C188.85 62.7022 189.279 62.7694 189.681 62.9037C190.084 63.038 190.446 63.2282 190.767 63.4782C191.088 63.7282 191.357 64.0378 191.58 64.4034C191.804 64.7728 191.961 65.1906 192.058 65.6569H190.857C190.793 65.3809 190.689 65.1272 190.547 64.8921C190.405 64.6571 190.23 64.4556 190.021 64.2878C189.812 64.1199 189.566 63.9856 189.282 63.8886C188.999 63.7916 188.685 63.7431 188.338 63.7431C187.95 63.7431 187.589 63.8177 187.253 63.9669C186.917 64.1162 186.626 64.34 186.376 64.6347C186.126 64.9294 185.928 65.2951 185.787 65.7316C185.645 66.168 185.574 66.668 185.574 67.235C185.574 67.7909 185.641 68.2871 185.779 68.7236C185.917 69.1601 186.111 69.5294 186.361 69.8316C186.611 70.1338 186.906 70.3651 187.245 70.5218C187.585 70.6785 187.962 70.7568 188.372 70.7568C189.073 70.7568 189.637 70.5778 190.062 70.2233C190.487 69.8689 190.767 69.4063 190.905 68.843H192.103V68.8504Z"
                    fill="white" />
                <path
                    d="M194.748 68.69H198.019L196.37 64.1087L194.748 68.69ZM195.691 62.8626H197.12L200.429 71.6485H199.098L198.407 69.7458H194.371L193.692 71.6485H192.431L195.691 62.8626Z"
                    fill="white" />
            </svg>
            <div class="progress-bar">
                <div class="progress-box">
                    <div class="progress-indicator" v-bind:style="{width: value + '%'}"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapActions } from 'vuex'

    export default {
        data() {
            return {
                value: 0
            }
        },
        mounted() {
            let loader = setInterval(() => {
                if (this.value < 100) {
                    this.value += 10;
                } else {
                    // setTimeout(() => {
                        this.fetchPage(true)
                    // }, 400)
                    clearInterval(loader)
                }
            }, 1) //150 норм
        },
        methods: mapActions(['fetchPage']),
    }
</script>
<style lang="scss" scoped>
    .preloader {
        position: fixed;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        background-color: #462DC7;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 99999999;
    }

    .preloader-wrapper {
        svg {
            animation: shining infinite 2s;
        }
    }

    .progress-bar {
        margin-top: 28px;
    }

    .progress-box {
        width: 100%;
        height: 6px;
        background-color: #001D82;
        position: relative;
    }

    .progress-indicator {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 6px;
        background: linear-gradient(107.47deg, #FFD200 -35.9%, #E62315 45.08%);
        transition: .3s width ease;
    }

    @keyframes shining {
        0% {
            opacity: 1;
        }

        50% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
</style>