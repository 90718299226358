<template>
    <div :class="[classes]">
        <div class="headline__text">
            <span v-html="text"></span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'headlineInside',
    props: {
        text: String,
        classes: String
    },
    mounted() {
        if(this.text) {
            const regExp = new RegExp('(<a.+)(href=")(?!' + window.location.origin + ')', 'gm')
            // eslint-disable-next-line
            this.text = this.text.replace(regExp, '$1 rel="nofollow" target="_blank" $2')
        }
    }
}
</script>

<style lang="scss" scoped>
.headline {
    position: relative;
    margin-bottom: 60px;
    padding: 20px;
    height: 586px;
    border-radius: 20px;
    // background: linear-gradient(94.5deg, #FFD200 0%, #FFF500 100%);
    background-image: url('../../../assets/images/qwe.png');
    background-size: cover;
    background-position: 0 0;
    background-repeat: no-repeat;
    &__text {
        display: flex;
        width: 100%;
        height: fit-content;
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 53px, var(--color1) 53px, var(--color1) 54px, rgba(255,255,255,0) 54px);
        background-size: 100% 56px;
        background-repeat: repeat-y;
        border-top: 1px solid var(--color1);
        >span {
            width: 100%;
            max-width: 1400px;
            font-size: 40px;
            line-height: 140%;
            color: var(--color1);
            text-transform: uppercase;
        }
    }
    &__career {
        .headline__text {
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 79px, var(--color1) 79px, var(--color1) 80px, rgba(255,255,255,0) 80px);
            background-size: 100% 82px;
            >span {
                max-width: 1500px;
            }
        }
        span {
            font-size: 60px;
            ::v-deep .decoration__red {
                color: var(--color3);
            }
            ::v-deep .decoration__yellow {
                color: var(--color4);
            }
            ::v-deep .decoration__pre {
                background: linear-gradient(90deg, #FFD200 0%, #E62315 54.25%, #462DC7 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                user-select: none;
            }
        }
        @include phones {
            .headline__text {
                background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 43px, var(--color1) 43px, var(--color1) 44px, rgba(255,255,255,0) 44px);
                background-size: 100% 44px;
                >span {
                    font-size: 32px;
                }
            }
        }
        @include largePhonesAlt {
            height: 340px;
            padding: 20px 10px;
            .headline__text {
                background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 33px, var(--color1) 33px, var(--color1) 34px, rgba(255,255,255,0) 34px);
                background-size: 100% 36px;
                >span {
                    font-size: 26px;
                }
            }
        }
        @include smallPhonesAlt {
            .headline__text {
                background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 23px, var(--color1) 23px, var(--color1) 24px, rgba(255,255,255,0) 24px);
                background-size: 100% 29px;
                >span {
                    font-size: 20px;
                }
            }
        }
    }
    @include phones {
        .headline__text {
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 43px, var(--color1) 43px, var(--color1) 44px, rgba(255,255,255,0) 44px);
            background-size: 100% 44px;
            >span {
                font-size: 32px;
            }
        }
    }
    @include largePhonesAlt {
        height: 340px;
        padding: 20px 10px;
        margin-bottom: 0;
        .headline__text {
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 33px, var(--color1) 33px, var(--color1) 34px, rgba(255,255,255,0) 34px);
            background-size: 100% 36px;
            >span {
                font-size: 26px;
            }
        }
    }
    @include smallPhonesAlt {
        .headline__text {
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 23px, var(--color1) 23px, var(--color1) 24px, rgba(255,255,255,0) 24px);
            background-size: 100% 29px;
            >span {
                font-size: 20px;
            }
        }
    }
}
.career__advances {
    width: 100%;
}
[data-bgtemplate='1'], [data-bgtemplate='3'] {
    .career__advances {
        .headline__text {
            border-top: 1px solid #fff;
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 81px, #fff 81px, #fff 82px, rgba(255,255,255,0) 82px);
            background-size: 100% 84px;
        }
        span {
            font-size: 60px;
            color: #fff;
        }
        @include mediumLaptop {
            .headline__text {
                background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 58px, #fff 58px, #fff 59px, rgba(255,255,255,0) 59px);
                background-size: 100% 61px;
            }
            span {
                font-size: 46px;
            }
        }
        @include mediumPhones {
            .headline__text {
                background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 47px, #fff 47px, #fff 48px, rgba(255,255,255,0) 48px);
                background-size: 100% 50px;
            }
            span {
                font-size: 36px;
            }
        }
        @include smallPhonesAlt {
            .headline__text {
                background: none;
            }
            span {
                font-size: 20px;
            }
        }
    }
}
[data-bgtemplate='2'] {
    .career__advances {
        .headline__text {
            border-top: 1px solid var(--color1);
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 81px, var(--color1) 81px, var(--color1) 82px, rgba(255,255,255,0) 82px);
            background-size: 100% 84px;
        }
        span {
            font-size: 60px;
            color: var(--color1);
        }
        @include mediumLaptop {
            .headline__text {
                background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 58px, var(--color1) 58px, var(--color1) 59px, rgba(255,255,255,0) 59px);
                background-size: 100% 61px;
            }
            span {
                font-size: 46px;
            }
        }
        @include mediumPhones {
            .headline__text {
                background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 47px, var(--color1) 47px, var(--color1) 48px, rgba(255,255,255,0) 48px);
                background-size: 100% 50px;
            }
            span {
                font-size: 36px;
            }
        }
        @include smallPhonesAlt {
            .headline__text {
                background: none;
            }
            span {
                font-size: 20px;
            }
        }
    }
}
.advances-grid__item, .headline__career {
    .headline__logo {
        display: none;
    }
}
</style>