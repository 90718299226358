<template>
  <div>
    <transition>
      <Loader v-if="!pageReady"/>
    </transition>
    <Header ref="header" />
    <transition>
      <div class="loaderIsUp" v-if="isLoading">
        <span class="loaderIsUp__logo"></span>
      </div>
    </transition>
    <div id="app" class="app" ref="app" :class="{pt0: $route.name === 'Index'}">
      <keep-alive>
        <Particles v-if="$route.name === 'Index' && !isIPhone"/>
      </keep-alive>
      <transition @leave="appOnLeave" @after-enter="appOnAfterEnter" @before-leave="appOnBeforeLeave">
        <router-view class="app__main__container"></router-view>
      </transition>
      <Footer />
    </div>
    <Modal :scrollPoint="scrPnt" :bsb="bodyScrollBar" :formFileds="modalInfo.formFileds" :content="modalInfo.content" :modalType="modalInfo.modalType" :quoteModal="modalInfo.quoteModal" />
    <Lightbox :lightboxSettings="lightboxSettings" :lbx="lbx" />
    <Cookie />
  </div>
</template>

<script>
  import {
    mapMutations,
    mapGetters
  } from "vuex";
  import Particles from './components/pages/index/particles.vue'
  import Loader from "./components/Loader.vue";
  import Header from "./pages/header.vue";
  import Footer from "./pages/footer.vue";
  import Modal from './components/pages/about/modal.vue'
  import Lightbox from './components/pages/about/lightbox.vue'
  import Cookie from './components/UI/cookie.vue'
  //Надо сделать, чтобы по умолчанию все было скрыто, а после того, как скрываем лоадер, то все показываем и отсюда будем плясать с анимацией
  import eventHub from './components/eventHub'
  import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';
  // import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll';
  import { gsap } from "gsap"
  import { ScrollTrigger } from 'gsap/ScrollTrigger';
  import { ScrollToPlugin } from "gsap/ScrollToPlugin";

  import onloadingAnimation from './utils/gsapPageAnimation'

  const COEF_SCROLL_NAVMENU = 0.18

  export default {
    name: "Hopper-IT",
    components: {
      Loader,
      Header,
      Footer,
      Modal,
      Lightbox,
      Cookie,
      Particles
    },
    // Добавление метатэгов через vue-meta
    metaInfo() {
      return {
        htmlAttrs: {
          lang: 'ru'
        },
        meta: [
          { 'charset': 'utf-8' },
          { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
          { 'name': 'viewport', content: 'width=device-width,initial-scale=1.0' },
          { 'property': 'og:title', content: 'Главная', template: '%s | Hopper-IT', vmid: 'og:title' },
          { 'property': 'og:type', content: 'website'},
          { 'property': 'og:site_name', content: 'Hopper-IT'},
          { 'property': 'og:url', content: 'https://hopper-it.ru/'},
          { 'property': 'og:image', content: 'https://hopper-it.ru/front/img/logo.79f0a422.svg'},
          { 'property': 'og:image:width', content: '274'},
          { 'property': 'og:image:height', content: '54'},
          { 'property': 'og:description', content: 'Создаем корпоративные порталы, интегрируем с IT-инфраструктурой, формируем эффективное рабочее пространство для любых отраслей', vmid: 'og:description'},
        ],
        title: 'Главная',
        titleTemplate: '%s | Hopper-IT',
        link: () => {
          //eslint-disable-next-line
          const mask = /\/\?.+=|^https?:\/\/[\w\-\.]+$|^https?:\/\/[\w\-\.]+\/$|^https?:\/\/[\w\-\.]+\/[\w\-]+$|^https?:\/\/[\w\-\.]+\/[\w\-\.]+\/$/g
          //eslint-disable-next-line
          //регулярка с двоеточием для теста локально const mask = /\/\?.+=|^https?:\/\/[\w\-\.\:]+$|^https?:\/\/[\w\-\.\:]+\/$|^https?:\/\/[\w\-\.\:]+\/[\w\-]+$|^https?:\/\/[\w\-\.\:]+\/[\w\-\.|;]+\/$/g //регулярка с двоеточием для теста локально
          const url = window.location.href
          if(url.match(mask)){
            return {
                link: [
                    {
                      rel: 'canonical',
                      href:`${window.location.href}`
                    }
                ]
            }
          }
        },
        script: [{
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'Organization',
            name: 'HOPPER IT',
            url: '//hopper-it.ru/',
            image: '//hopper-it.ru/front/img/logo.79f0a422.svg',
            description: '«HOPPER IT» - разработка корпоративных порталов, внедрение баз знаний, разработка корпоративных мобильных приложений, решения для HR, автоматизация процессов.',
            telephone: '+7(495)191-24-22',
            address: {
              '@type': 'PostalAddress',
              streetAddress: 'Варшавское шоссе, 9, стр. 1',
              addressLocality: 'Москва',
              addressRegion: 'Москва',
              addressCountry: 'Россия'
            }
          }
       }],
      }
    },
    data() {
      return {
        modalInfo: {},
        bodyScrollBar: null,
        tl: null,
        tlHeader: null,
        tlList: [],
        footerAnim: null,
        isModalUp: false,
        newCasesEls: {},
        lbx: {},
        lightboxSettings: [],
        iW: 0,
        scrPnt: 0,
        delta: 0,
        passedSwiper: {
          item: undefined,
          clicked: false
        },
        metaLink: [],
        headerHeight: 0,
        headerMarginTop: 0,
        swScroll: 0,
        momentumSpeed: 50,
        hLimit: 0,
        prevTime: 0
      };
    },
    updated() {
      if (
        (this.modalInfo.quoteModal && this.modalInfo.quoteModal.visible) ||
        (this.lbx && this.lbx.visible)
      ) {
          this.bodyScrollBar.updatePluginOptions('modal', { open: true });
      } else {
        this.bodyScrollBar.updatePluginOptions('modal', { open: false });
      }
      ScrollTrigger.refresh()
    },
    created() {
      this.updateWidth({
        width: document.documentElement.clientWidth
      })
      window.addEventListener("resize", () => {
        this.updateWidth({
          width: document.documentElement.clientWidth
        })
        this.myEventHandler(window)
      });
    },
    mounted() {
      document.addEventListener('click', this.closeDropDowns)
      this.momentumSpeed = this.iW > 768 ? 50 : 10
      this.headerHeight = this.widthPage > 768 ? 93 : 56

      gsap.set(this.$refs.header.$el, {y: -this.headerHeight, duration: 0})
      this.tlHeader = gsap.timeline()
      this.tlHeader.to(this.$refs.header.$el, {y: 0, duration: .15, ease: 'power0'})
      this.iW = window.innerWidth
      eventHub.$on('updateLoader', (isUp) => {
        this.indexAnimation()
        this.setLoading(isUp)
        isUp || onloadingAnimation(this.$route.name || this.$route.path)
      })
      eventHub.$on('loadService', () => {
        this.indexAnimation()
      })
      eventHub.$on('scrollHeightUpdate', () => {
        this.bodyScrollBar.update()
      })
      eventHub.$on('aboutOnAfterEnter', () => {
        this.tlHeader.play()
        this.animateFooter('appOnAfterEnter')
        this.animateH2s('appOnAfterEnter')
        this.casesAnimation()
        ScrollTrigger.refresh()
      })
      eventHub.$on('aboutOnBeforeLeave', () => {
        this.bodyScrollBar.updatePluginOptions('modal', { firstScroll: false });
        this.bodyScrollBar.setMomentum(0, 0)
        this.bodyScrollBar.scrollTo(0, 0);
      })
      eventHub.$on('aboutOnLeave', () => {
        ScrollTrigger.getAll().forEach(i => i.kill())
      })
      eventHub.$on('emitLightbox', (data) => {
        this.lbx = data.lbx
        this.lightboxSettings = data.lightboxSettings
      })
      eventHub.$on('emitModal', (data) => {
        this.findScrollPoint()
        this.modalInfo = data
      })
      eventHub.$on('solutionsDone', () => {
        setTimeout(() => {
          this.animateSolutions('mounted')
          ScrollTrigger.refresh()
        }, 100);
      })
      eventHub.$on('passSwiper', data => {
        this.passedSwiper.item = data
      })
      eventHub.$on('filterUpd', (duration) => {
        this.tlHeader.play()
        if (this.bodyScrollBar.offset.y > 0) {
          this.bodyScrollBar.scrollTo(0, 0, duration, {
            callback: () => {
              ScrollTrigger.refresh()
            }
          });
        }
        ScrollTrigger.refresh()
      })
      eventHub.$on('goBackToServices', () => {
        ScrollTrigger.getAll().forEach(i => i.kill())
        setTimeout(() => {
          this.casesAnimation()
          this.animateFooter('goBackToServices')
          this.stickyReplacement()
          ScrollTrigger.refresh()
        }, 1230);
      })
      eventHub.$on('chooseService', (duration) => {
        this.tlHeader.play()
        if (this.bodyScrollBar.offset.y > 0) {
          this.bodyScrollBar.scrollTo(0, 0, duration, {
            callback: () => {
              ScrollTrigger.refresh()
            }
          });
        }
        const elems = gsap.utils.toArray('.animate-elem')
        elems.forEach((i) => {
          this.setAnimation(i)
        })
        eventHub.$emit('updAnimation')
      })
      eventHub.$on('updAnimation', () => {
        ScrollTrigger.getAll().forEach(i => i.kill())
        const delay = this.$route.path === '/career' ? 310 : 0
        setTimeout(() => {
          this.casesAnimation()
          this.animateFooter('updateServicesOnClick')
          this.stickyReplacement()
          ScrollTrigger.refresh()
        }, delay);
      })
      eventHub.$on('updateServicesOnClick', () => {
        ScrollTrigger.getAll().forEach(i => i.kill())
        setTimeout(() => {
          this.casesAnimation()
          this.animateFooter('updateServicesOnClick')
          this.stickyReplacement()
          ScrollTrigger.refresh()
        }, 0);
      })
      const _this = this
      class DisableScrollPlugin extends ScrollbarPlugin {
        static pluginName = 'disableScroll';

        static defaultOptions = {
          direction: null,
        };

        transformDelta(delta) {
          if (this.options.direction) {
            delta[this.options.direction] = 0;
          }

          return { ...delta };
        }
      }
      class ModalPlugin extends ScrollbarPlugin {
        static pluginName = 'modal';
        static defaultOptions = {
          open: false,
          oldDelta: 0,
          scrollPoint: 0,
          firstScroll: false,
          scrollOnce: true
        };
        transformDelta(delta) {
          const scrollSettings = async () => {
            if (!this.options.firstScroll) {
              ScrollTrigger.getAll().forEach(i => i.kill())
              _this.animateFooter('ModalPlugin')
              _this.animateSolutions()
              _this.stickyReplacement()
              _this.casesAnimation()
              _this.animateH2s(`loaded info`)
              _this.indexAnimation()
              ScrollTrigger.refresh()
              return true
            }
            return true
          }
          scrollSettings().then((stgns) => {
            this.options.firstScroll = stgns
          })

          this.options.oldDelta = delta.y
          if (this.options.open || _this.headerIsOpen) {
            return { x: 0, y: 0 }
          } else {
            return {
              x: 0,
              y: delta.y * (_this.iW > 700 ? 1.15 : 0.6)
            };
          }
        }
      }
      Scrollbar.use(ModalPlugin, DisableScrollPlugin);
      this.bodyScrollBar = Scrollbar.init(this.$refs.app, {
          damping: _this.iW > 700 ? 0.015 : 0.03,
          delegateTo: document,
          alwaysShowTracks: false,
          renderByPixels: true,
          plugins: {
            disableScroll: { direction: 'x' }
          }
      });
      this.bodyScrollBar.setPosition(0, 0);
      this.bodyScrollBar.track.xAxis.element.remove()
      gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
      ScrollTrigger.scrollerProxy(this.$refs.app, {
        scrollTop(value) {
          if (arguments.length) {
            _this.bodyScrollBar.scrollTop = value; // setter
          }
          return _this.bodyScrollBar.scrollTop; // getter
        }
      });
      this.bodyScrollBar.addListener((q) => {
        if (this.hLimit !== q.limit.y) {
          this.hLimit = q.limit.y
          ScrollTrigger.refresh()
        }
        this.findScrollOffset(q.offset.y)
        this.bodyScrollBar.updatePluginOptions('modal', { scrollPoint: q.offset.y });
        if (this.$route.name === 'Services') {
          const $newPage = document.querySelector('.section__wrapper__newpage')
          if ($newPage) {
            const {bottom: npb} = $newPage.getBoundingClientRect()
            eventHub.$emit('serviceBackgroundUpdate', {type: 'scroll', nmber: npb})
          }
        }
        if (this.bodyScrollBar._momentum.y > this.momentumSpeed) {
          //TODO Check if 786 or 1200 needed up here
          // if (this.widthPage <= 768) {
          if (this.widthPage <= 1200) {
            this.headerMarginTop = -56
          } else {
            this.headerMarginTop = -93
          }
          this.tlHeader.reverse()
        } else if (this.bodyScrollBar._momentum.y < this.momentumSpeed*-1) {
          this.headerMarginTop = 0
          this.tlHeader.play()
        }
        ScrollTrigger.update()
      });
      ScrollTrigger.defaults({ scroller: this.$refs.app, pinType: 'transform' })
      this.animateFooter('Mounted')
      this.casesAnimation()
      this.animateH2s()
      this.indexAnimation()
      ScrollTrigger.refresh()

      // Исправляет баг с каруселью и гладким скроллом
      let damping = 0;
      let pointerCount = 0;

      this.bodyScrollBar.containerEl.addEventListener('touchstart', () => {
        if (pointerCount === 0) {
          damping = this.bodyScrollBar.options.damping
        }

        pointerCount++
      })

      this.bodyScrollBar.containerEl.addEventListener('touchend', () => {
        pointerCount--

        if (pointerCount === 0) {
          this.bodyScrollBar.options.damping = damping
        }
      });

      // only for prod markers correction
      // if (document.querySelector('.gsap-marker-scroller-start')) {
      //   const markers = gsap.utils.toArray('[class *= "gsap-marker"]');
      //    _this.bodyScrollBar.addListener(({ offset }) => {
      //     gsap.set(markers, { marginTop: -offset.y })
      //   });
      // }
    },
    onBeforeUnmount() {
      eventHub.$off('emitLightbox')
      eventHub.$off('emitModal')
      eventHub.$off('solutionsDone')
      eventHub.$off('passSwiper')
      eventHub.$off('filterUpd')
      eventHub.$off('updateServicesOnClick')
      eventHub.$off('updAnimation')
      eventHub.$off('goBackToServices')
      eventHub.$off('updateLoader')
      eventHub.$off('scrollHeightUpdate')
      eventHub.$off('aboutOnAfterEnter')
      eventHub.$off('aboutOnBeforeLeave')
      eventHub.$off('aboutOnLeave')
      ScrollTrigger.getAll().forEach(i => i.kill())
    },
    methods: {
      ...mapMutations(["updateWidth", "findScrollOffset", "setLoading"]),
      casesAnimation() {
        const ones = gsap.utils.toArray('.case-item__modified__container');
        if (ones.length) {
          ScrollTrigger.create({
              trigger: '.casses__inner__hr',
              start: this.iW > 1200 ? 'top top+=120px' : 'top top',
              endTrigger: ones[ones.length - 1],
              end: 'top top+=130px',
              pin: true
          })
          ScrollTrigger.create({
              trigger: '.casses__inner__left',
              start: this.iW > 1200 ? 'top top+=135px' : 'top top-=135px',
              endTrigger: ones[ones.length - 1],
              end: 'top top+=130px',
              pin: true
          })
          this.casesTimeline = gsap.timeline({duration: 0, ease: "none"})
          ones.forEach((item, index) => {
            if (this.iW > 1200) {
              this.casesTimeline.fromTo(item, {x: 0},{
                scrollTrigger: {
                  trigger: item,
                  start: `top top+=${index * 10 + 350}px`,
                  end: `top top+=${index * 15 + 130}px`,
                  scrub: true,
                },
                x: `${-25 * index}`,
                ease: 'none',
              })
            }
            this.casesTimeline.to(item, {
              scrollTrigger: {
                trigger: item,
                start: this.iW > 1200 ? `top top+=${index * 15 + 156}px` : `top top+=${index * 15 + 56}px`,
                // start: `top top+=${index * 15 - item.offsetHeight / 3}px`,
                endTrigger: ones[ones.length - 1],
                end: 'top top+=170px',
                pin: true,
                onUpdate: self => {
                  if (self.vars.trigger === self.vars.endTrigger) {
                    self.progress === 1 && ScrollTrigger.refresh();
                  }
                }
              }
            })
          })
        }
      },
      setAnimation(trigger, duration = 1, delay = 0) {
        let listElems = null
        let direction = null
        let setOption = null

        if(!trigger.parentElement.className.match('hidden-wrapper') && trigger.className.match('wrap')) {
          this.wrap(trigger)
        }

        switch (true) {
          case Boolean(trigger.className.match('listfadeInLeft')) || Boolean(trigger.className.match('listfadeInRight')):
            duration = 0.3
            listElems = trigger.children

            direction = trigger.className.match('listfadeInLeft') ? 'left' : 'right'
            if(!trigger.dataset.animated) {
              gsap.set(trigger, {opacity: 1})
              listElems.forEach((elem) => {
                gsap.set(elem, {opacity: 0, xPercent: direction === 'left' ? -100 : 100})
              })
            }
            ScrollTrigger.create({
              trigger,
              onToggle: () => {
                if(!trigger.dataset.animated) {
                  listElems.forEach((elem, id) => {
                    delay = (0 + (duration * 0.66)) * id
                    gsap.to(elem, {
                      opacity: 1,
                      xPercent: 0,
                      duration,
                      delay,
                      ease: 'power1.out'
                    })
                  })
                }
                trigger.dataset.animated = true
              },
              start: 'top bottom -=15%'
            })
            break
          case Boolean(trigger.className.match('fadeInLeftSmall')) ||
                Boolean(trigger.className.match('fadeInRightSmall')) ||
                Boolean(trigger.className.match('fadeInUpSmall')) ||
                Boolean(trigger.className.match('fadeInDownSmall')):

            direction = trigger.className.match('fadeInLeftSmall') ? 'left' :
                        trigger.className.match('fadeInRightSmall') ? 'right' :
                        trigger.className.match('fadeInUpSmall') ? 'up' : 'down'

            setOption = direction === 'left' ? {xPercent: -20} :
                        direction === 'right' ? {xPercent: 20} :
                        direction === 'up' ? {yPercent: 5} : {yPercent: -5}

            delay = trigger.className.match('second') ? 0.3 : 0

            if(!trigger.dataset.animated) {
              gsap.set(trigger, {opacity: 0, ...setOption})
            }
            ScrollTrigger.create({
              trigger,
              onToggle: () => {
                if(!trigger.dataset.animated) {
                  gsap.to(trigger, {
                    opacity: 1,
                    xPercent: 0,
                    yPercent: 0,
                    ease: "power3.out",
                    duration,
                    delay
                  })
                }
                trigger.dataset.animated = true
              },
              start: 'top bottom -=15%'
            })
            break
          case Boolean(trigger.className.match('fadeInLeft')) ||
                Boolean(trigger.className.match('fadeInRight')) ||
                Boolean(trigger.className.match('fadeInUp')) ||
                Boolean(trigger.className.match('fadeInDown')):

            direction = trigger.className.match('fadeInLeft') ? 'left' :
                        trigger.className.match('fadeInRight') ? 'right' :
                        trigger.className.match('fadeInUp') ? 'up' : 'down'

            setOption = direction === 'left' ? {xPercent: -100} :
                        direction === 'right' ? {xPercent: 100} :
                        direction === 'up' ? {yPercent: 50} : {yPercent: -50}

            // start = trigger.className.match('start-top') ? 'top' : ''

            delay = trigger.className.match('second') ? 0.3 : 0

            if(!trigger.dataset.animated) {
              gsap.set(trigger, {opacity: 0, ...setOption})
            }
            ScrollTrigger.create({
              trigger,
              onToggle: () => {
                if(!trigger.dataset.animated) {
                  gsap.to(trigger, {
                    opacity: 1,
                    xPercent: 0,
                    yPercent: 0,
                    ease: "power3.out",
                    duration,
                    delay
                  })
                }
                trigger.dataset.animated = true
              },
              start: 'top bottom -=15%'
            })
            break
          case Boolean(trigger.className.match('shiftLeft')) ||
                Boolean(trigger.className.match('shiftRight')) ||
                Boolean(trigger.className.match('shiftUp')) ||
                Boolean(trigger.className.match('shiftDown')):

            direction = trigger.className.match('shiftLeft') ? 'left' :
                        trigger.className.match('shiftRight') ? 'right' :
                        trigger.className.match('shiftUp') ? 'up' : 'down'

            setOption = direction === 'left' ? {xPercent: -100} :
                        direction === 'right' ? {xPercent: 100} :
                        direction === 'up' ? {yPercent: 120} : {yPercent: -120}

            // start = trigger.className.match('start-top') ? 'top' : ''

            if(!trigger.dataset.animated) {
              gsap.set(trigger, {...setOption, opacity: 0})
            }
            ScrollTrigger.create({
              trigger,
              onToggle: () => {
                if(!trigger.dataset.animated) {
                  gsap.to(trigger, {opacity: 1, duration: 0})
                  gsap.to(trigger, {
                    xPercent: 0,
                    yPercent: 0,
                    ease: "power1.out",
                    duration,
                    delay: direction === 'left' ? duration * 0.7 : 0
                  })
                 }
                 trigger.dataset.animated = true
              },
              start: direction === 'up' || direction === 'down' ? 'top bottom+=15%' : 'center bottom-=10%'
            })
            break
          default:
              console.log()
        }
      },
      wrap(elem, className = 'hidden-wrapper') {
        const wrapper = document.createElement('div');
        wrapper.classList = className;

        elem.before(wrapper)
        wrapper.prepend(elem)
      },
      indexAnimation(){
        const elems = gsap.utils.toArray('.animate-elem')

        if (typeof elems !== 'object' || !elems.length) {
            return
        }

        elems.forEach((i) => {
          this.setAnimation(i)
        })
      },
      animateH2s() {
        const targets = gsap.utils.toArray(".h2animated > span");
        const targetSpans = gsap.utils.toArray(".spanAnimated > span");
        const targetButtons = gsap.utils.toArray(".buttonsAnimated");
        const targetTabs = gsap.utils.toArray(".tabsAnimated");

        const qqweqwe = gsap.utils.selector('.casses__inner.section')
        gsap.set(qqweqwe('.case-item.case-item__modified'), {y: 500})
            ScrollTrigger.create({
              trigger: '.casses__inner.section',
              start: 'top center-=150px',
              animation: gsap.to(qqweqwe('.case-item.case-item__modified'), {y: 0, duration: 1})
            })
        if (targets.length) {
          targets.forEach(i => {
            ScrollTrigger.create({
              trigger: i.parentElement,
              animation: gsap.fromTo(i, {yPercent: 100}, {yPercent: 0, ease: "power3.out", duration: 1, onComplete: function() {
                if (this._targets[0].closest('.casses__inner.section')) {
                  const hr = this._targets[0].closest('.casses__inner.section').querySelector('.casses__inner__hr > hr')
                  gsap.to(hr, {width: '100%', duration: 1, ease: 'none'})
                }
              }}),
              start: 'bottom bottom'
            });
          })
        }
        if (targetSpans.length) {
          targetSpans.forEach(i => {
            ScrollTrigger.create({
              trigger: i.parentElement,
              animation: gsap.fromTo(i, {y: 300}, {y: 0, ease: "power3.out", duration: 1,}),
              start: 'bottom bottom-=100px'
            })
          })
        }
        if (targetButtons.length) {
          targetButtons.forEach(i => {
            ScrollTrigger.create({
              trigger: i,
              animation: gsap.fromTo(i.querySelector('button'), {x: -300}, {x: 0, ease: "power3.out", duration: 1,}),
              start: 'bottom bottom'
            })
          })
        }
        if (targetTabs.length) {
          targetTabs.forEach(i => {
            const q = gsap.utils.selector(i)
            ScrollTrigger.create({
              trigger: i,
              animation: gsap.fromTo(q('button'), {yPercent: 100, opacity: 0}, {yPercent: 0, opacity: 1, ease: "power3.out", duration: 1,}),
              start: 'bottom bottom-=100px'
            })
          })
        }
      },
      animateFooter() {
        const footerAnim = gsap.utils.toArray("footer > section");
          footerAnim.forEach(i => {
            ScrollTrigger.create({
              trigger: '#footer',
              animation: gsap.fromTo(i, {y: -199}, {y: 0}),
              start: 'top bottom',
              endTrigger: '#footer',
              end: 'bottom bottom',
              scrub: true,
              ease: 'none'
            });
          })
      },
      animateSolutions() {
        let activeScrollbar = this.bodyScrollBar
        this.casesTimeline23 = gsap.timeline({duration: 0, ease: "none"})
        const buttons = gsap.utils.toArray('a[data-qq]')
        const buttonsAdaptive = gsap.utils.toArray('a[data-qq-mobile] > .scroll')
        if (buttons.length) {
          buttons.forEach(i => {
            i.addEventListener('click', function() {
              const offset = document.getElementById(i.dataset.qq).offsetTop
              gsap.to(activeScrollbar, {scrollTo: offset, ease: "none"})
            })
            this.casesTimeline23.fromTo(i, {background: 'linear-gradient(to right, #FFD200 0%, #fff 0%)'}, {
              scrollTrigger: {
                trigger: `#${i.dataset.qq}`,
                start: `top center`,
                end: 'bottom center',
                scrub: true,
                onUpdate: self => {
                  i.style.background = `linear-gradient(to right, #FFD200 ${self.progress * 100}%, #fff ${self.progress * 100}%)`
                }
              },
              ease: 'none'
            })
          })
        }
        if (buttonsAdaptive.length) {
          buttonsAdaptive.forEach(i => {
            const triggerId = i.closest('a').dataset.qqMobile
            i.closest('a').addEventListener('click', () => {
              this.passedSwiper.clicked = true
              const offset = document.getElementById(triggerId).offsetTop
              gsap.to(activeScrollbar, {scrollTo: offset, ease: "none", onComplete: () => {
                this.passedSwiper.item.setProgress(this.swScroll - COEF_SCROLL_NAVMENU, 330)
                this.passedSwiper.clicked = false
              }})
            })
            this.casesTimeline23.fromTo(i, {right: '100%'}, {
              scrollTrigger: {
                trigger: `#${triggerId}`,
                start: `top top`,
                end: 'bottom top',
                scrub: true,
                onUpdate: self => {
                  i.style.right = `${100 - self.progress * 100}%`
                }
              },
              ease: 'none'
            })
          })
        }
      },
      stickyReplacement() {
        const self = this
        const topSpacing = this.iW > 1200 ? 93 : 56
        if (this.$route.name === 'Services' && this.widthPage >= 1200) {
          if (document.querySelector('.menu__container__sticky')) {
            ScrollTrigger.create({
              id: 'bebe1',
              trigger: '.menu__container__sticky',
              start: `top top+=${topSpacing}px`,
              endTrigger: () => {
                return document.querySelector('.section__wrapper__directions') ? '.section__wrapper__directions' : '.section__wrapper__newpage'
              },
              end: 'bottom bottom',
              pin: true,
              onUpdate: self => {
                this.adjustStickyElementToHeader(self, topSpacing)
              }
            })
            ScrollTrigger.create({
              id: 'bebe2',
              trigger: '.arrow__container__sticky',
              start: `top top+=${self.$refs.header.$el.clientHeight}px`,
              endTrigger: () => {
                return document.querySelector('.section__wrapper__directions') ? '.section__wrapper__directions' : '.section__wrapper__newpage'
              },
              end: 'bottom bottom',
              pin: true,
              onUpdate: self => {
                this.adjustStickyElementToHeader(self, topSpacing)
              }
            })
          }
        }
        if (document.querySelector("[data-position='sticky']")) {
          ScrollTrigger.create({
            id: 'stickyTest',
            trigger: "[data-position='sticky']",
            start: () => {
              if (this.widthPage > 1200) {
                if (this.$route.name) {
                  const page = this.$route.name.toLowerCase()
                  switch (page) {
                    case 'license':
                    case 'solutions':
                    case 'cases':
                    case 'events':
                      return `top top+=${this.testingHT + 130}px`
                    case 'case':
                    case 'solution':
                      return `top top+=${this.testingHT + 25}px`
                    default:
                      return `top top+=${this.testingHT + 13}px`
                  }
                }
                return `top top+=${this.testingHT + 13}px`
              } else {
                if (this.widthPage > 1200) {
                  return `top top+=${this.testingHT + 15}px`
                } else {
                  return `top top+=${this.testingHT}px`
                }
              }
            },
            endTrigger: '[data-position-end="sticky"]',
            end: `bottom bottom`,
            pin: true,
            onUpdate: (self) => {
              // FIXME Throws an error
              this.swScroll = self.progress

              if (document.querySelectorAll('[data-qq-mobile]').length) {
                this.passedSwiper.clicked || this.passedSwiper.item.setProgress(this.swScroll - COEF_SCROLL_NAVMENU, 0)
              }
              this.adjustStickyElementToHeader(self, this.testingHT)
            }
          })
        }
      },
      adjustStickyElementToHeader(self, topSpacing) {
        if (parseInt(self.trigger._gsap.y) <= topSpacing) {
          gsap.to(self.trigger.childNodes, {y: this.headerMarginTop === 0 ? 0 : -parseInt(self.trigger._gsap.y), duration: .3, ease: 'power0'})
        } else {
          gsap.to(self.trigger.childNodes, {y: this.headerMarginTop, duration: .15, ease: 'power0'})
        }
      },
      appOnLeave: function(el, done) {
        ScrollTrigger.getAll().forEach(i => i.kill())
        done()
      },
      appOnAfterEnter: function() {
        // this.setLoading(true)
        this.tlHeader.play()
        this.animateFooter('appOnAfterEnter')
        this.casesAnimation()
        this.indexAnimation()
        ScrollTrigger.refresh()
      },
      appOnBeforeLeave: function() {
        this.isActive = true
        this.bodyScrollBar.updatePluginOptions('modal', { firstScroll: false });
        this.bodyScrollBar.setMomentum(0, 0)
        this.bodyScrollBar.scrollTo(0, 0);
      },
      myEventHandler(e) {
        this.iW = e.innerWidth
        this.momentumSpeed = this.iW > 768 ? 50 : 10
        this.headerHeight = this.iW > 768 ? 93 : 56

        this.animateSolutions()
        ScrollTrigger.getById('stickyTest') && ScrollTrigger.getById('stickyTest').refresh()
        ScrollTrigger.refresh()
      },
      findScrollPoint() {
        this.scrPnt = Math.round(this.bodyScrollBar.options.plugins.modal.scrollPoint / (this.bodyScrollBar.limit.y / 100))
      }
    },
    computed: {
      ...mapGetters(['widthPage','headerIsOpen', 'animationPages', 'isLoading', 'pageReady', 'isIPhone']),
      testingHT() {
        return this.iW > 1200 ? 93 : 56
      }
    },
    watch: {
      $route() {
        //eslint-disable-next-line
        const mask = /\/\?.+=|^https?:\/\/[\w\-\.]+$|^https?:\/\/[\w\-\.]+\/$|^https?:\/\/[\w\-\.]+\/[\w\-]+$|^https?:\/\/[\w\-\.]+\/[\w\-\.]+\/$/g
        //eslint-disable-next-line
      //регулярка с двоеточием для теста локально  // const mask = /\/\?.+=|^https?:\/\/[\w\-\.\:]+$|^https?:\/\/[\w\-\.\:]+\/$|^https?:\/\/[\w\-\.\:]+\/[\w\-]+$|^https?:\/\/[\w\-\.\:]+\/[\w\-\.|;]+\/$/g
        const url = window.location.href
        if(url.match(mask)){
          this.metaLink = {
            link: [
              {
                rel: 'canonical',
                href:`${window.location.href}`
              }
            ]
          }
        } else {
          this.metaLink = {}
        }
        return this.metaLink
      }
    },
    destroyed() {
      window.removeEventListener("resize", this.myEventHandler);
    },
  };
</script>

<style lang="scss" scoped>
  .header__background {
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 0;
    height: calc(100vh - var(--headerHeight));

    @include smallLaptop {
      min-height: calc(100vh - var(--headerHeightMobile));
      height: 100vh; /* Fallback for browsers that do not support Custom Properties */
      height: calc(var(--vh, 1vh) * 100 - var(--headerHeightMobile));
    }

    @include largePhonesAlt {
      min-height: 100vh;
      min-height: calc(var(--vh, 1vh) * 100 - var(--headerHeightMobile));
      height: fit-content;
    }

    @include largePhones {
      overflow: hidden;
    }
  }

  .app {
    box-sizing: border-box;
    padding-top: 93px;
    height: 100vh;
    overflow: auto;
    position: relative;
    background: var(--color5);

    @include mediumLaptopAlt {
      padding-top: var(--headerHeightMobile);
    }
    &.pt0 {
      padding-top: 0;
    }
  }

  .animation-enter {
    transform: translateY(-100px);
    opacity: 0;
  }

  .animation-enter-active {
    transition: transform 0.6s ease-in, 0.6s opacity ease-in;
  }

  .animation-leave-active {
    transition: opacity 0.3s ease-in;
  }

  .animation-leave-to {
    opacity: 0;
  }


  .scrollingBody {
    padding-bottom: var(--paddingTop);
    padding-top: var(--paddingTop);
    z-index: 1;

    max-width: $mainMaxWidth;
    margin: auto;

    @include laptop {
      padding-bottom: var(--paddingTopLaptop);
      padding-top: var(--paddingTopLaptop);
    }

    @include smallLaptop {
      padding-bottom: var(--paddingTopPhones);
      padding-top: calc(var(--headerHeightMobile) + 24px);
      min-height: calc(100vh - 253px);
      padding-bottom: var(--paddingTop);
      z-index: 1;
    }

    @include mediumLaptop {
      .scrollingBody {
        min-height: calc(100vh - 223px);
      }
    }

    @include largePhones {
      padding-bottom: var(--paddingTopPhones);
    }

    @include phones {
      padding-bottom: var(--paddingBottomPhones);
    }

    @include laptop {
      padding-bottom: var(--paddingTopLaptop);
      padding-top: var(--paddingTopLaptop);
    }

    @include smallLaptop {
      padding-bottom: var(--paddingTopPhones);
      padding-top: calc(var(--headerHeightMobile) + 24px);
    }
  }

  @font-face {
    font-family: "SuisseIntl";
    font-weight: 100;
    font-style: normal;
    font-display: auto;
    unicode-range: U+000-5FF;
    src: local("SuisseIntl"),
      url("../src/assets/fonts/SuisseIntl/SuisseIntl-Light.woff") format("woff");
  }
// ONLY FOR DEVELOP
.scroll-setting {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 32px 32px 16px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: rgb(175, 175, 175);
  box-shadow: 3px -3px 7px 0px rgba(0,0,0,.15);
  >label {
    padding-bottom: 6px;
    margin-bottom: 16px;
    border-bottom: 1px solid rgb(199, 199, 199);
    display: flex;
    flex-direction: column;
    >span {
      color: #fff;
      font-weight: 600;
      letter-spacing: 0.1rem;
      &.n {
        font-size: 11px;
        text-transform: uppercase;
      }
    }
  }
}
@keyframes qqwe {
	0% {
		background-position: 0% 0%;
	}
	50% {
		background-position: 0% 50%;
	}
	100% {
		background-position: 0% 100%;
	}
}
@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
.loaderIsUp {
  display: flex;
  // display: none;
  z-index: 9;
  position: fixed;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 300px, rgba(0,29,130, 1) 100%);
  background-size: 200% 200%;

  display: flex;
  justify-content: center;
  align-items: center;
  &__logo {
    animation: flash 1.5s infinite 0s ease-in-out;
    width: 270px;
    height: 50px;
    background-image: url("../src/assets/images/logo-hopper.svg");
    background-size: cover;
  }
}

  ::v-deep {
    .animate-elem {
      opacity: 0;
    }
  }
</style>
